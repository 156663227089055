<template>
  <div class="home">
    <div class="bg" v-if="showOKMsg">
      <div class="dialog">
        <img class="dialog-img" src="/title.jfif" />
        <p class="dialog-title">感谢提交</p>
        <p class="dialog-text">感谢您的参与</p>
      </div>
    </div>
    <div class="top">
      <p class="title">调查问卷</p>
    </div>
    <div class="center">
      <p class="text">{{survey.subject}}</p>
      <p class="wenzi">
        {{survey.description}}
      </p>
    </div>
    <div class="bottom">
      <div class="title2">
        <p class="p"><b>调&nbsp;查&nbsp;内&nbsp;容</b></p>
      </div>

      <div class="question" v-for="(val, idx) in survey.question" :key="idx">
        <div class="issue">{{val.id}}.{{val.title}}</div>
        
        <div v-if="val.type=='1'" class="answerbox">
          <p class="p_2" v-for="(val2,idx2) in val.answer" :key="idx2">
            <input
              type="radio"
              :name="val.element_name"
              :value="val2.value"
            />&nbsp;&nbsp;{{val2.text}}
          </p>
        </div>

        <div v-if="val.type=='2'" class="answerbox">
          <p class="p_2" v-for="(val2,idx2) in val.answer" :key="idx2">
            <input
              type="checkbox"
              :name="val.element_name"
              :value="val2.value"
            />&nbsp;&nbsp;{{val2.text}}
          </p>
        </div>

        <div v-if="val.type=='3'" class="answerbox">
            <input type="text" class="inpu" :name="val.element_name" />
        </div>

        <div v-if="val.type=='4'" class="answerbox">
          <div class="one1" v-for="(val2,idx2) in val.answer" :key="idx2">
            <span class="span1"> &nbsp;&nbsp;{{val2.text}}</span>
            &nbsp;&nbsp;<input
              type="text"
              class="inpu"
              :name="val2.name"
            />
          </div>
        </div>

      </div>
      
      <div class="button">
        <button class="submit" @click="submitb()" v-if="flag"><b>点击提交</b></button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import "../assets/app.js";

function hex_sha1(s) {
  return binb2hex(core_sha1(AlignSHA1(s)));
}
function core_sha1(blockArray) {
  var x = blockArray; // append padding
  var w = Array(80);
  var a = 1732584193;
  var b = -271733879;
  var c = -1732584194;
  var d = 271733878;
  var e = -1009589776;
  for (
    var i = 0;
    i < x.length;
    i += 16 // 每次处理512位 16*32
  ) {
    var olda = a;
    var oldb = b;
    var oldc = c;
    var oldd = d;
    var olde = e;
    for (
      var j = 0;
      j < 80;
      j++ // 对每一个512位进行80步操做
    ) {
      if (j < 16) {
        w[j] = x[i + j];
      } else {
        w[j] = rol(w[j - 3] ^ w[j - 8] ^ w[j - 14] ^ w[j - 16], 1);
      }

      var t = safe_add(
        safe_add(rol(a, 5), sha1_ft(j, b, c, d)),
        safe_add(safe_add(e, w[j]), sha1_kt(j))
      );
      e = d;
      d = c;
      c = rol(b, 30);
      b = a;
      a = t;
    }
    a = safe_add(a, olda);
    b = safe_add(b, oldb);
    c = safe_add(c, oldc);
    d = safe_add(d, oldd);
    e = safe_add(e, olde);
  }
  return new Array(a, b, c, d, e);
}
function sha1_ft(t, b, c, d) {
  if (t < 20) {
    return (b & c) | (~b & d);
  }
  if (t < 40) {
    return b ^ c ^ d;
  }
  if (t < 60) {
    return (b & c) | (b & d) | (c & d);
  }
  return b ^ c ^ d; // t<80
}
function sha1_kt(t) {
  return t < 20
    ? 1518500249
    : t < 40
    ? 1859775393
    : t < 60
    ? -1894007588
    : -899497514;
}
function safe_add(x, y) {
  var lsw = (x & 0xffff) + (y & 0xffff);
  var msw = (x >> 16) + (y >> 16) + (lsw >> 16);
  return (msw << 16) | (lsw & 0xffff);
}
function rol(num, cnt) {
  return (num << cnt) | (num >>> (32 - cnt));
}
function AlignSHA1(str) {
  var nblk = ((str.length + 8) >> 6) + 1,
    blks = new Array(nblk * 16);
  for (var i = 0; i < nblk * 16; i++) {
    blks[i] = 0;
  }
  for (i = 0; i < str.length; i++) {
    blks[i >> 2] |= str.charCodeAt(i) << (24 - (i & 3) * 8);
  }
  blks[i >> 2] |= 0x80 << (24 - (i & 3) * 8);
  blks[nblk * 16 - 1] = str.length * 8;
  return blks;
}
function binb2hex(binarray) {
  var hex_tab = "0123456789abcdef";
  var str = "";
  for (var i = 0; i < binarray.length * 4; i++) {
    str +=
      hex_tab.charAt((binarray[i >> 2] >> ((3 - (i % 4)) * 8 + 4)) & 0xf) +
      hex_tab.charAt((binarray[i >> 2] >> ((3 - (i % 4)) * 8)) & 0xf);
  }
  return str;
}
function getClientId() {
  var id = localStorage.getItem("clientid");
  console.log(id);
  if (!id || id == "") {
    id = hex_sha1(navigator.userAgent + new Date().getTime() + Math.random());
    localStorage.setItem("clientid", id);
  }
  return id;
}

//63329e998891b9aa250ccd3c

export default {
  components: {},
  data() {
    const uuid = this.$route.params.uuid!='' ? this.$route.params.uuid : '';
    if( uuid != '' ){
      console.log('uuid:', uuid);
      this.$axios
        .post(
            "survey/detail",
            {
            uuid: uuid,
            }
        )
        .then((res) => {
          if(res.data.code==0){
            this.survey.subject = res.data.data.subject;
            this.survey.description = res.data.data.description;
            this.survey.question = res.data.data.question;
            this.flag = true;
          }else{
            this.survey.subject = '调查不存在或不在调查时间内';
            this.survey.description = '';
            this.survey.question = [];
          }
        });
    }

    return {
      flag: false,
      showOKMsg: this.$cookies.get("showokmsg"),
      survey : {
        subject : '',
        description: '',
        quesionts: [],
      },
      alltext: {
        uuid: uuid,
        clientid: getClientId(),
        school: "",
        grade: "",
        discipline: "",
        policy: "",
        course: "",
        job: "",
        train: "",
        internship: "",
        help: "",
        attend: "",
        personal: {
          name: "",
          mobile: "",
          wechat: "",
        },
      },
      submit: {},
    };
  },

  methods: {
    blur() {
      if (this.alltext.personal.mobile != "") {
        var reg = /^1[3-9]{1}[0-9]{9}$/;
        if (reg.test(this.alltext.personal.mobile)) {
          console.log("合法手机号");
        } else {
          console.log("非法手机号");
        }
      }
    },
    blure() {
      if (this.alltext.personal.wechat != "") {
        var wxPattern = /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/;
        if (wxPattern.test(this.alltext.personal.wechat)) {
          console.log("合法微信号");
        } else {
          console.log("非法微信号");
        }
      }
    },

    submitb() {
      const {
        uuid,
        clientid,
        school,
        grade,
        discipline,
        policy,
        course,
        job,
        train,
        internship,
        help,
        attend,
        personal,
      } = this.alltext;
      const obj = {
        uuid,
        clientid,
        school,
        grade,
        discipline,
        policy,
        course,
        job,
        train,
        internship,
        help,
        attend,
        personal: {
          name: personal.name,
          mobile: personal.mobile,
          wechat: personal.wechat,
        },
      };
      if (
        obj.uuid &&
        obj.clientid &&
        obj.school &&
        obj.grade &&
        discipline &&
        obj.policy &&
        obj.course &&
        obj.job &&
        obj.train &&
        obj.internship &&
        obj.help &&
        obj.attend &&
        obj.personal.name &&
        obj.personal.mobile &&
        obj.personal.wechat
      ) {
        axios({
          method: "post",
          url: "survey/submit",
          data: obj,
        }).then(
          () => {
            this.showOKMsg = true;
            this.$cookies.set("showokmsg", true);
          },
          () => {
            alert("失败");
          }
        );
      } else {
        alert("失败");
      }
    },
  },
};
</script>
<style scoped>
* {
  list-style: none;
  font-size: 0.16rem;
  margin: 0px;
}

.dialog-title {
  color: #2c53d4;
  font-weight: 700;
  font-size: 16px;
  margin-top: 10px;
}

.dialog-text {
  color: #888888;
  font-size: 14px;
  margin: 10px;
  margin-bottom: 20px;
}
.bg {
  z-index: 101;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.dialog {
  background-color: white;
  position: fixed;
  left: 0;
  right: 0;
  top: 30%;
  margin: 0 auto;
  width: 300px;
  border-radius: 10px;
}

.dialog-img {
  width: 80px;
  margin-top: 20px;
}
.home {
  background: #3266cc;
  font-size: 0.16px;
  height: auto;
}
.top {
  width: 100%;
  height: 1rem;
}
.title {
  line-height: 1rem;
  font-size: 0.625rem;
  color: yellow;
}
.center {
  width: 90%;
  height: 1.35rem;
  /* background: yellow; */
  border: 2px solid black;
  background: white;
  margin-left: 0.03rem;
  border-radius: 0.05rem;
  margin: 0 auto;
  margin-top: 0.2rem;
}
.text {
  width: 100%;
  height: auto;
  margin: 0 auto;
  font-size: 0.13rem;
  font-weight: bold;
  margin-top: 0.08rem;
}
.wenzi {
  width: 98%;
  height: auto;
  margin-top: 0.08rem;
  font-size: 0.14rem;
  margin-left: 0.03rem;
  text-align: left;
}
.bottom {
  width: 90%;
  height: auto;
  border: 2px solid black;
  border-radius: 0.05rem;
  margin-left: 0.06rem;
  background: white;
  margin: 0 auto;
  margin-top: 0.4rem;
}
.title2 {
  width: 100%;
  height: 0.5rem;
  /* background: yellow; */
  border-radius: 0.05rem;
  text-align: center;
  line-height: 0.5rem;
}
.one1 {
  width: 100%;
  height: 0.5rem;
  background: white;
  line-height: 0.5rem;
}
.span1 {
  float: left;
}
.inpu {
  width: 2rem;
  border-left: none;
  border-right: none;
  border-top: none;
  outline: none;
}

.p_2 {
  width: 100%;
  height: auto;
  margin-top: 0.1rem;
  font-size: 0.14rem;
}


.question {
  width:100%;
  height: auto;
  text-align:left;
  margin-bottom: 15px;
}
.question .issue{
  color:rgb(44, 83, 212);
  padding-left: 10px;
  font-size:0.16rem;
}

.question .answerbox{
  margin-left:0.24rem;
  width:94%;
  font-size: 0.14rem;
}

.question .answerbox .span1{
  font-size: 0.14rem;
}

.button {
  width: 100%;
  height: 0.6rem;
  /* background: red; */
  margin-top: 0.2rem;
}
.submit {
  width: 2rem;
  height: 0.45rem;
  background: #f6d351;
  border: 2px solid black;
  border-radius: 0.05rem;
  color: #2c58c2;
}

.alert {
  width: 1rem;
  font-size: 0.5rem;
  background-color: aqua;
}
</style>
