<template>
  <el-container class="home">
    <el-aside width="200px">
      <admin-menu></admin-menu>
      <router-view></router-view>
    </el-aside>
    
    <el-main style="padding:0px;">
      
      <div class="tc">
        <span class="san" @click="tcdl">退出登录</span>
      </div>
      <div class="listtitle">
        <p class="titlep">问卷列表</p>
        <button class="btn-blue" @click="addQuestion">添加问卷</button>
      </div>
      
      <el-table :data="tableData">
        <el-table-column
          v-for="(key, value, index) in tableHeader"
          :key="index"
          :prop="value"
          :label="key"
          :formatter="questionStatus"
        >
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="240">
          <template slot-scope="scope">
            <el-button @click="handleAnswer(scope.row)" type="text" size="small">
              <span class="spantit">调查结果</span>
            </el-button>
            <el-button @click="handleEdit(scope.row)" type="text" size="small">
              <span class="spantit">编辑</span>
            </el-button>
            <el-button @click="handleStatus(scope.row)" type="text" size="small">
              <span class="spantit" v-if="(scope.row.status==0)">开启</span>
              <span class="spantit" v-if="(scope.row.status==1)">停止</span>
            </el-button>
            <el-button @click="handleUrl(scope.row)" type="text" size="small">
              <span class="spantit">获取地址</span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="prev, pager, next"
        :page-count="data.total_page"
        @current-change="handleCurrentChange"
        :current-page="data.page"
      >
      </el-pagination>
    </el-main>
  </el-container>
</template>

<script>
import AdminMenu from "@/components/AdminMenu.vue";
export default {
    name: 'QuestionListView',
    components: {AdminMenu},
    data() {
      const token = this.$cookies.get("token");
      let page = this.$route.query.page ? this.$route.query.page : 1;
      if (!token) {
        location.href = "/admin/login";
      }
      this.$axios
      .post(
        "question/list",
        {
          page: page,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        const data = res.data.data;
        this.tableData = data.list;
        this.data = data;
      });
      return {
        tableData: [],
        tableHeader: {
          id: 'ID',
          subject: '调查主题',
          start_time: '开始时间',
          end_time: '结束时间',
          status: '状态',
          answer_total: '参与人数',
        },
        data: {},
      };
    },
    methods: {
      questionStatus(row, col) {
        if(col.property != 'status'){
          return row[col.property];
        }
        if (row[col.property]==1){
          return '正常';
        }else{
          return '停用';
        }
      },
      addQuestion() {
        location.href = `/admin/question/add`;
      },
      handleEdit(row) {
        location.href = `/admin/question/edit?id=${row.id}`;
      },
      handleStatus(row) {
        let txt = row.status==1 ? '确定要停用吗?' : '确定要启用吗？';
        let newStatus = row.status==1 ? 0 : 1;
        let id = row.id;
        const token = this.$cookies.get("token");
        this.$confirm(txt, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$axios
                .post(
                  "question/onoff",
                  {
                    id: id,
                    status: newStatus,
                  },
                  {
                    headers: {
                      Authorization: token,
                    },
                  }
                )
                .then((res) => {
                  if ( res.data.code == 0 ){
                    row.status = newStatus;
                  }
                });
            })

      },
      handleAnswer(row) {
        location.href = `/admin?uuid=${row.uuid}`;
      },
      handleCurrentChange(newPage) {
        console.log(newPage);
        location.href = "/admin/question?page=" + newPage;
      },
      handleUrl(row) {
        let url = window.location.protocol + window.location.host + '/survey/' + row.uuid;
        if(window.clipboardData){
          window.clipboardData.setData('text', url);
        }else{
          const ele = document.createElement('input');
          ele.setAttribute('value', url);
          document.body.appendChild(ele);
          ele.select();
          document.execCommand('copy');
          document.body.removeChild(ele);
        }
        this.$message({type:'success',message:'地址已经复制到剪贴板！',duration:1000,offset:300});
      },
      tcdl() {
          this.$confirm("确定要退出吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              window.sessionStorage.clear();
              this.$cookies.remove("token");
              setTimeout(() => {
                this.$router.push({
                  name: "login",
                  replace: true,
                });
              }, 1000);
            });
      },
    },
};
</script>

<style>
.btn-blue {
  line-height: 28px;
  font-size: 14px;
  padding: 0 10px 0 10px;
  width:90px;
  margin: auto 50px;
  background-color: #006EFF;
  color:#FFFFFF;
  border:none;
  cursor:pointer;
}
.btn-blue:hover {
  background-color: #0063E5;
}
</style>
