<template>
  <div class="login">
    <el-form
      style="width: 500px; margin: 0 auto; margin-top: 60px"
      ref="form"
      :model="form"
    >
      <h1>登陆</h1>
      <el-form-item label="用户名">
        <el-input v-model="form.username"></el-input>
      </el-form-item>
      <el-form-item label="密码">
        <el-input v-model="form.password" type="password"></el-input>
      </el-form-item>
      <el-button type="primary" @click="onSubmit" class="butt"
        ><span>登录</span></el-button
      >
    </el-form>
  </div>
</template>

<script>
import { Loading } from "element-ui";

export default {
  name: "LoginView",
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    async onSubmit() {
      console.log(this.form.username);
      const load = Loading.service();
      await this.$axios
        .post("admin/login", {
          username: this.form.username,
          password: this.form.password,
        })
        .then((res) => {
          if (res.data.code == 0) {
            load.close();
            // this.$message({
            //   message: "登陆成功",
            //   type: "success",
            // });
            const token = res.data.data.token;
            console.log(token);
            this.$cookies.set("token", token);
            location.href = "/admin";
          } else {
            load.close();
            // this.$message({
            //   message: "登陆失败:" + res.data.msg,
            //   type: "error",
            // });
          }
        })
        .catch((e) => {
          load.close();
          this.$message({
            message: "错误:" + e,
            type: "error",
          });
        });
    },
  },
};
</script>
<style>
.login {
  margin-top: -20px;
  padding-top: 20px;
  background-color: #1e222d;
  height: 100vh;
  color: white;
}
.el-form-item__label {
  color: white !important;
}
.butt {
  width: 100%;
  height: 80px;
}
</style>
