<template>
  <el-container class="home">
    <el-aside width="200px">
      <admin-menu></admin-menu>
      <router-view></router-view>
    </el-aside>
    
    <el-main style="padding:0px;">
      
      <div class="tc">
        <!-- <div class="tc-top" style="font-size: 12px; color: #c1c6c8">
          <el-breadcrumb separator="/" style="font-size: 12px; color: #c1c6c8">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item><a href="/">活动管理</a></el-breadcrumb-item>
        </el-breadcrumb>
        </div> -->
        <span class="san" @click="tcdl">退出登录</span>
      </div>
      <div class="listtitle">
        <p class="titlep">查看列表</p>
      </div>
      
      <el-table :data="tableData">
        <el-table-column
          v-for="(key, value, index) in tableHeader"
          :key="index"
          :prop="value"
          :label="key"
        >
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small"
              ><span class="spantit">查看详情</span></el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="prev, pager, next"
        :page-count="data.total_page"
        @current-change="handleCurrentChange"
        :current-page="data.page"
      >
      </el-pagination>
    </el-main>
  </el-container>
</template>
<style>
.home {
  height: 100%;
  padding-top: 0px !important;
}
.listtitle{
    width: 100%;
    height: 60px;
    background: #f8f9fa;
    display: flex;
    justify-content: space-between;
}
.titlep{
  width: 100%;
  height: 60px;
  /* background: red; */
  font-size: 16px;
  color: #000000;
  font-weight: 700; 
  display: flex;
  margin-left: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 60px;
}

.tc {
  width: 100%;
  height: 38px;
  background: #1e222d;
  display: flex;
  flex-direction: row-reverse;
}
.tc-top{
  height: 38px;
}
.san {
  width: 90px;
  height: 38px;
  /* background: cyan; */
  color: #00a6ff;
  font-size: 12px;
  line-height: 38px;
}
.san:hover{
  cursor: pointer;
}
.cell{
  font-size: 12px;
  color: #888888;
  font-weight: 700;
}
.spantit{
  font-size: 12px;
  color: #006eff;
  font-weight: 700;
}
.el-table th.el-table__cell>.cell{
  padding-left: 20px !important;
}
.el-table .cell{
  padding-left: 20px !important;

} 
</style>
<script>
// @ is an alias to /src
import AdminMenu from "@/components/AdminMenu.vue";
export default {
  name: "TableView",
  components: { AdminMenu },
  data() {
    const token = this.$cookies.get("token");
    let page = this.$route.query.page ? this.$route.query.page : 1;
    if (!token) {
      location.href = "/admin/login";
    }

    let uuid = this.$route.query.uuid;
    if(!uuid){
      uuid = '';
    }

    this.$axios
      .post(
        "answer/list",
        {
          page: page,
          uuid: uuid,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        const data = res.data.data;
        this.tableHeader = data.header;
        this.tableData = data.list;
        this.data = data;
      });
    return {
      tableData: [],
      tableHeader: {},
      data: {},
    };
  },
  methods: {
    handleClick(row) {
      location.href = `/admin/detail/${row.clientid}`;
      console.log(row.clientid);
    },
    handleCurrentChange(newPage) {
      console.log(newPage);
      location.href = "/admin?page=" + newPage;
    },
    tcdl() {
        this.$confirm("确定要退出吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            window.sessionStorage.clear();
            this.$cookies.remove("token");
            setTimeout(() => {
              this.$router.push({
                name: "login",
                replace: true,
              });
            }, 1000);
          })
      this.$confirm("确定要退出吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      this.$axios.post("account/logout").then(() => {
        this.$cookies.remove("token");
        setTimeout(() => {
          this.$router.push({
            name: "login",
            replace: true,
          });
        }, 3000);
      });
    },
  },
};
</script>
