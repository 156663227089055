<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  created() {
    document.title = "调查问卷";
  },
};
</script>
<style lang="scss">
body {
  margin: 0px;
  height: 100%;
}

html {
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100%;
  // color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
