<template>
  <el-container class="home">
    <el-aside width="200px">
      <admin-menu></admin-menu>
      <router-view></router-view>
    </el-aside>
    
    <el-main style="padding:0px;font-size:12px;text-align:left;">
      
      <div class="tc">
        <span class="san" @click="tcdl">退出登录</span>
      </div>
      <div class="listtitle">
        <p class="titlep">{{page_title}}</p>
        <button class="btn-blue" @click="jumpBack">返回</button>
      </div>
      
      <div style="padding:10px;color: #888888;">

        <el-form :model="form" label-width="100px">

          <h5 style="text-align:left;color: #888888;">基本信息</h5>

          <el-form-item label="调查主题:" col-span="16">
            <el-input v-model="form.subject" class="w500" show-word-limit maxlength="60"></el-input>
          </el-form-item>

          <el-form-item label="调查简介:" col-span="16">
            <el-input v-model="form.description" class="w500" type="textarea" show-word-limit maxlength="300" ></el-input>
          </el-form-item>

          <el-form-item label="是否启用:" col-span="16">
            <el-switch v-model="form.status" ></el-switch>
          </el-form-item>

          <el-form-item label="调查时间:" col-span="16">
            <el-date-picker v-model="form.datetimerange" type="datetimerange" 
              range-separator="至" start-placeholde="开始时间" end-placeholde="结束时间"></el-date-picker>
          </el-form-item>

          <h5 style="text-align:left;color: #888888;">调查内容</h5>

          <el-form-item v-for="(val,idx) in form.questions" :key="idx" :label="val.label">
            <el-row>
                <el-col style="width:460px">
                    <div>是否必填:<input type="checkbox" v-model="val.required" /></div>
                    <div>问题：<el-input v-model="val.title" class="w400" show-word-limit  maxlength="60"></el-input></div>
                    <div v-for="(v2, idx2) in val.answer" :key="idx2">
                    <label v-if="(val.type!=4)">子选项</label><label v-if="(val.type==4)">子问题</label>：
                    <el-input v-model="v2.issue" class="w300" show-word-limit maxlength="60"></el-input>
                    <i v-if="(idx2 == 0)" class="el-icon-circle-plus" @click="addItem(idx)"></i>
                    <i v-if="(idx2 > 0)" class="el-icon-remove" @click="delItem(idx,idx2)"></i>
                </div>
                </el-col>
                <el-col style="width:30px;height:100%;">
                  <el-button type="danger" icon="el-icon-delete" size="mini" style="margin-top:50px" circle @click="delRow(idx)"></el-button>
                </el-col>
            </el-row>
          </el-form-item>

        </el-form>

        <el-divider></el-divider>

        <el-button type="primary" size="mini" style="margin-left:50px" @click="submit"><span style="font-size:14px">保存</span></el-button>
        <span class="addLink">
          <el-link type="primary" @click="addTextSingle">+添加单文本</el-link>
          <el-link type="primary" @click="addTextMutil">+添加多文本</el-link>
          <el-link type="primary" @click="addCheckBox">+添加单选项</el-link>
          <el-link type="primary" @click="addRadioBox">+添加多选项</el-link>
        </span>
        
      </div>
      
    </el-main>
  </el-container>
</template>

<script>
import AdminMenu from "@/components/AdminMenu.vue";
function getNowFormatDate(date) {
   var  seperator1 =  "-" ;
   var  year = date.getFullYear();
   var  month = date.getMonth() + 1;
   var  strDate = date.getDate();
   var  hour = date.getHours();
   var  min = date.getMinutes();
   var  sec = date.getSeconds();
   if  (month >= 1 && month <= 9) {
     month =  "0"  + month;
   }
   if  (strDate >= 0 && strDate <= 9) {
     strDate =  "0"  + strDate;
   }
   if  (hour >= 0 && hour <= 9) {
    hour =  "0"  + hour;
   }
   if  (min >= 0 && min <= 9) {
     min =  "0"  + min;
   }
   if  (sec >= 0 && sec <= 9) {
     sec =  "0"  + sec;
   }
   var  currentdate = year + seperator1 + month + seperator1 + strDate + ' ' + hour + ':' + min + ':' + sec;
   return  currentdate;
}
export default({
    components: { AdminMenu },
    data() {
      const token = this.$cookies.get("token");
      if (!token) {
        location.href = "/admin/login";
      }

      let id = this.$route.query.id ? this.$route.query.id*1 : 0;
      let title = id ? '编辑问卷': '添加问卷';

      if( id>0 ){
        this.$axios
        .post(
            "question/detail",
            {
            id: id,
            },
            {
            headers: {
                Authorization: token,
            },
            }
        )
        .then((res) => {
            if(res.data.code==0){
                this.form.subject = res.data.data.subject;
                this.form.description = res.data.data.description;
                this.form.status = res.data.data.status ? true : false;
                this.form.datetimerange = [
                    new Date(res.data.data.start_time),
                    new Date(res.data.data.end_time),
                ];

                for(const el of res.data.data.question){
                    let label = '未知类型';
                    switch(el.type*1){
                        case 1:
                            label = '单选项';
                            break;
                        case 2:
                            label = '多选项';
                            break;
                        case 3:
                            label = '单文本';
                            break;
                        case 4:
                            label = '多文本';
                            break;
                    }
                    let item = { label:label, required: el.required, title: el.title, type: el.type, answer: [] };
                    for(const issue of el.answer){
                        item.answer.push({issue:issue});
                    }
                    this.form.questions.push(item);
                }

            }else{
                this.$message.error('获取问卷信息失败，请重试!');
            }
            console.log(res.data);
        });
      }

      return {
        page_title: title,
        tableData: [],
        form: {
            id: id,
            subject: '',
            description: '',
            status: true,
            datetimerange: ['', ''],
            questions: [],
        },
      };
    },
    methods: {
        addTextSingle() {
          this.form.questions.push({ label:'单文本', required: 1, title: '输入问题', type: 3, answer: [] });
        },
        addTextMutil() {
          this.form.questions.push({ label:'多文本', required: 1, title: '输入问题', type: 4, answer: [{ issue: '子问题' }, { issue: '子问题' }] });
        },
        addCheckBox() {
          this.form.questions.push({ label:'多选项', required: 1, title: '输入问题', type: 2, answer: [{ issue: '子选项' }, { issue: '子选项' }] });
        },
        addRadioBox() {
          this.form.questions.push({ label:'单选项', required: 1, title: '输入问题', type: 1, answer: [{ issue: '子选项' }, { issue: '子选项' }] });
        },
        delRow(idx) {
            this.form.questions.splice(idx, 1);
        },
        addItem(idx) {
            console.log(idx);
            let txt = this.form.questions[idx].type==4 ? '子问题' : '子选项';
            this.form.questions[idx].answer.push({issue:txt});
        },
        delItem(idx,idx2) {
            this.form.questions[idx].answer.splice(idx2, 1);
        },
        jumpBack() {
            window.history.go(-1);
        },
        tcdl() {
          this.$confirm("确定要退出吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              window.sessionStorage.clear();
              this.$cookies.remove("token");
              setTimeout(() => {
                this.$router.push({
                  name: "login",
                  replace: true,
                });
              }, 1000);
            })
      },
      submit() {
        let args = {
            id: this.form.id,
            subject: this.form.subject,
            description: this.form.description,
            status: this.form.status ? 1 : 0,
            start_time: getNowFormatDate(this.form.datetimerange[0]),
            end_time: getNowFormatDate(this.form.datetimerange[1]),
            question: [],
        };

        for(const key in this.form.questions){

            let answer = [];
            for(const el of this.form.questions[key].answer){
                answer.push(el.issue);
            }

            args.question.push({
                rank: key*1+1,
                required: this.form.questions[key].required,
                title: this.form.questions[key].title,
                type: this.form.questions[key].type,
                answer: answer,
            });

        }

        let api = this.form.id > 0 ? 'question/edit' : 'question/add'; 

        this.$axios
        .post(
            api,
            args,
            {
            headers: {
                Authorization: this.$cookies.get("token"),
            },
            }
        )
        .then((res) => {
            if ( res.data.code == 0 ){
                this.$message({type:'success',message:'保存成功！',duration:2000,offset:300,onClose:function(){
                    location.href = `/admin/question`;
                }});

            }else{
                this.$message({type:'error', message:'保存失败，请检查内容或稍后再试!', duration:3000,offset:300});
            }
        });

      },
    },
})
</script>

<style>
.el-form-item__label{
    color: #888888 !important;
}
.w500{
    width:500px !important;
}
.w400{
    width: 400px !important;
}
.w300{
    width: 300px !important;
    margin-top:5px;
}
.addLink{
    line-height:24px;
    margin-left: 30px;
}
.addLink a{margin-right: 10px;}
.addLink .el-link--inner{font-size: 14px;}
.el-message {height: 30px;}
.el-message .el-icon-error{font-size:24px;}
.el-message .el-icon-success{font-size:24px;}
</style>
