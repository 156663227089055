<template>
  <el-container class="home">
    <el-aside width="200px">
      <admin-menu></admin-menu>
    </el-aside>
    <el-main>
      <h1>{{ data.subject }}</h1>
      <div v-for="(item, index) in data.list" :key="index">
        <h4 style="text-align: left; margin-left: 150px">
          {{ item.id }}. {{ item.title }}
        </h4>
        <p
          style="text-align: left; margin-left: 150px"
          v-for="(answer, j) in item.answer"
          :key="j"
        >
          {{ answer }}
        </p>
      </div>
      <div class="button">
        <button class="butt" @click="butto">返回上一页</button>
      </div>
    </el-main>
  </el-container>
</template>

<script>
// @ is an alias to /src
import "../assets/reset.css";
import AdminMenu from "@/components/AdminMenu.vue";

export default {
  name: "DetailView",
  components: { AdminMenu },
  data() {
    return {
      id: this.$route.params.id,
      data: {},
    };
  },
  mounted() {
    const token = this.$cookies.get("token");
    if (!token) {
      location.href = "/admin/login";
    }
    this.$axios
      .post(
        "answer/detail",
        {
          clientid: this.id,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        const data = res.data.data;
        console.log(data);
        this.data = data;
      });
  },
  methods: {
    butto() {
      this.$router.push({
        name: "table",
        replace: true,
      });
    },
  },
};
</script>
<style>
.button {
  width: 100%;
  height: 200px;
  /* background: red; */
}

.butt {
  width: 100px;
  height: 40px;
  position: relative;
  top: -140px;
}
</style>
