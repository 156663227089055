<template>
  <el-menu
    class="my-el-menu-vertical"
    style="height: 100%;border:none;"
    background-color="#1E222D"
    text-color="#fff"
    active-text-color="#fff"
    :default-active="$router.currentRoute.path"
    :default-openeds="['/']"
    router
  >
    <el-submenu index="/">
      <template slot="title">
        <span class="page">首页</span>
      </template>
      <el-menu-item index="/admin">调查列表</el-menu-item>
    </el-submenu>
    <el-submenu index="1">
      <template slot="title">
          <span class="page">管理</span>
      </template>
      <el-menu-item index="/admin/question">问卷列表</el-menu-item>
    </el-submenu>
  </el-menu>
</template>
<script>
export default {
  data() {
    console.log(this.$router.currentRoute.path);
    return {};
  },
};
</script>
<style>


.el-menu-item.is-active {
  /* width: 200px; */
  height: 38px  !important;
  line-height: 38px !important;
  background-color: #006eff !important;
}

.el-submenu__title {
  width: 200px;
  height: 38px !important;
  line-height: 38px !important;
  text-align: left;
  padding-left: 30px !important;
}
.page{
  font-size: 12px;
  color: #c1c6c8;
}
</style>
