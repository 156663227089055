import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TableView from '../views/TableView.vue'
import LoginView from '../views/LoginView'
import DetailView from '../views/DetailView'
import QuestionListView from '../views/QuestionListView'
import QuestionEditView from '../views/QuestionEditView'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/survey/:uuid/',
    name: 'survey',
    component: HomeView
  },
  {
    path: '/admin',
    name: 'table',
    component: TableView,
    meta: {
      title: '查看列表'
    }

  },
  {
    path: '/admin/login',
    name: 'login',
    component: LoginView,
    meta: {
      title: '登陆'
    }

  },
  {
    path: '/admin/detail/:id/',
    name: 'detail',
    component: DetailView,
    meta: {
      title: '查看详情'
    }
  },
  {
    path: '/admin/question',
    name: 'question',
    component: QuestionListView,
    meta: {
      title: '问卷列表'
    }
  },
  {
    path: '/admin/question/add',
    name: 'questionadd',
    component: QuestionEditView,
    meta: {
      title: '添加问卷'
    }
  },
  {
    path: '/admin/question/edit',
    name: 'questionedit',
    component: QuestionEditView,
    meta: {
      title: '编辑问卷'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router